.glitch-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.glitch-wrapper h1.glitch {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  position: relative;
  /* font-size: 80px; */
  padding: 30px;
}

.glitch-wrapper h1.glitch::before,
.glitch-wrapper h1.glitch::after {
  padding: 30px;
  color: rgb(18, 238, 48);
  content: "DIDs";
  position: absolute;
  width: 100%;
  /* height: 100%; */
  background: #19350d;
  /* background: #19350d; */
  overflow: hidden;
  top: 0;
}

.glitch-wrapper h1.glitch::before {
  left: 3px;
  text-shadow: -2px 0 red;
  animation-name: glitch-animation-1;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: reverse-alternate;
}

.glitch-wrapper h1.glitch::after {
  left: -3px;
  text-shadow: -2px 0 blue;
  animation-name: glitch-animation-2;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: reverse-alternate;
}


@keyframes glitch-animation-1 {
  0% {
    clip: rect(43px, 430px, 13px, 30px);
  }

  5% {
    clip: rect(118px, 430px, 111px, 30px);
  }

  10% {
    clip: rect(116px, 430px, 6px, 30px);
  }

  15% {
    clip: rect(82px, 430px, 94px, 30px);
  }

  20% {
    clip: rect(16px, 430px, 107px, 30px);
  }

  25% {
    clip: rect(30px, 430px, 116px, 30px);
  }

  30% {
    clip: rect(29px, 430px, 16px, 30px);
  }

  35% {
    clip: rect(105px, 430px, 83px, 30px);
  }

  40% {
    clip: rect(80px, 430px, 50px, 30px);
  }

  45% {
    clip: rect(142px, 430px, 85px, 30px);
  }

  50% {
    clip: rect(55px, 430px, 125px, 30px);
  }

  55% {
    clip: rect(88px, 430px, 81px, 30px);
  }

  60% {
    clip: rect(27px, 430px, 140px, 30px);
  }

  65% {
    clip: rect(91px, 430px, 78px, 30px);
  }

  70% {
    clip: rect(107px, 430px, 45px, 30px);
  }

  75% {
    clip: rect(29px, 430px, 139px, 30px);
  }

  80% {
    clip: rect(69px, 430px, 20px, 30px);
  }

  85% {
    clip: rect(80px, 430px, 3px, 30px);
  }

  90% {
    clip: rect(92px, 430px, 47px, 30px);
  }

  95% {
    clip: rect(52px, 430px, 103px, 30px);
  }

  100% {
    clip: rect(138px, 430px, 25px, 30px);
  }
}

@keyframes glitch-animation-2 {
  0% {
    clip: rect(12px, 430px, 75px, 30px);
  }

  5% {
    clip: rect(101px, 430px, 21px, 30px);
  }

  10% {
    clip: rect(122px, 430px, 38px, 30px);
  }

  15% {
    clip: rect(78px, 430px, 60px, 30px);
  }

  20% {
    clip: rect(72px, 430px, 134px, 30px);
  }

  25% {
    clip: rect(103px, 430px, 10px, 30px);
  }

  30% {
    clip: rect(25px, 430px, 137px, 30px);
  }

  35% {
    clip: rect(142px, 430px, 109px, 30px);
  }

  40% {
    clip: rect(134px, 430px, 130px, 30px);
  }

  45% {
    clip: rect(76px, 430px, 136px, 30px);
  }

  50% {
    clip: rect(148px, 430px, 146px, 30px);
  }

  55% {
    clip: rect(39px, 430px, 117px, 30px);
  }

  60% {
    clip: rect(53px, 430px, 140px, 30px);
  }

  65% {
    clip: rect(20px, 430px, 24px, 30px);
  }

  70% {
    clip: rect(16px, 430px, 143px, 30px);
  }

  75% {
    clip: rect(58px, 430px, 21px, 30px);
  }

  80% {
    clip: rect(63px, 430px, 123px, 30px);
  }

  85% {
    clip: rect(18px, 430px, 88px, 30px);
  }

  90% {
    clip: rect(88px, 430px, 118px, 30px);
  }

  95% {
    clip: rect(9px, 430px, 1px, 30px);
  }

  100% {
    clip: rect(25px, 430px, 34px, 30px);
  }
}




/* ----------------------------- */

.green-box {
  position: relative;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  transition: 0.5s;
  /* margin-bottom: 18%; */
}

@media only screen and (max-width: 600px){
  .green-box {width:400px;}
}

.green-box::before {
  content: ' ';
  position: absolute;
  top: 0;
  /* left: 50px; */
  width: 35%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.green-box::after {
  content: '';
  position: absolute;
  top: 0;
  /* left: 50; */
  width: 35%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.green-box:hover:before,
.green-box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);

}

.green-box:nth-child(1):before,
.green-box:nth-child(1):after {
  background: linear-gradient(315deg, #000000, #76F935)
}

.green-box:nth-child(2):before,
.green-box:nth-child(2):after {
  background: linear-gradient(315deg, #5fc72b, #292828)
}

.green-box:nth-child(3):before,
.green-box:nth-child(3):after {
  background: linear-gradient(315deg, #4dff03, #535353)
}

.green-box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.green-box span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.green-box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
  z-index: -1;
  position: inherit;
}

.green-box span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(179, 177, 177, 0.08) 0.08;
  animation-delay: -1s;
  
}

.green-box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {

  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.green-box .green-box-content {
  position: relative;
  left: 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.green-box:hover .green-box-content {
  /* left: -25px; */
  padding: 20px;
}


/* ---------------------------- */

.box {
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.box::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.box:hover:before,
.box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);

}

.box:nth-child(1):before,
.box:nth-child(1):after {
  background: linear-gradient(315deg, #505202, #27401a)
}

.box:nth-child(2):before,
.box:nth-child(2):after {
  background: linear-gradient(315deg, #5fc72b, #292828)
}

.box:nth-child(3):before,
.box:nth-child(3):after {
  background: linear-gradient(315deg, #4dff03, #535353)
}

.box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.box span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.box span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(179, 177, 177, 0.08) 0.08;
  animation-delay: -1s;
}

.box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {

  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.box .content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.box:hover .content {
  left: -25px;
  padding: 60px 40px;
}

.box .content h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.box .content p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
}

.box .content a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.box .content a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
/* tux ------------------------*/
.tux {
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.tux::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(-15deg);
  transition: 0.5s;
}

.tux::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(-15deg);
  transition: 0.5s;
  filter: blur(30px);
}


.tux:nth-child(1):before,
.tux:nth-child(1):after {
  background: linear-gradient(315deg, #505202, #27401a)
}

.tux:nth-child(3):before,
.tux:nth-child(3):after {
  background: linear-gradient(315deg, #5fc72b, #292828)
}

.tux:nth-child(2):before,
.tux:nth-child(2):after {
  background: linear-gradient(315deg, #4dff03, #535353)
}

.tux span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.tux span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.tux span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(179, 177, 177, 0.08) 0.08;
  animation-delay: -1s;
}

.tux:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
.tux:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
.tux:hover .content {
  left: -25px;
  padding: 60px 40px;
}
.tux:hover:before,
.tux:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);

}

@keyframes animate {

  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.tux .content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}


.tux .content h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.tux .content p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
}

.tux .content a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.tux .content a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
/* ------------------------------- */

.animated-button-mainnet {
  /* background: linear-gradient(-30deg, #76F935 50%, #76F935 50%); */
  background:#76F935;
  padding: 10px 20px;
  /* margin: 12px; */
  border-radius: 4px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #000000;
  font-size: 18px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}
.animated-button-testnet {
  /* background: linear-gradient(-30deg, #76F935 50%, #76F935 50%); */
  background:#f7fb02;
  padding: 10px 20px;
  /* margin: 12px; */
  border-radius: 4px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #000000;
  font-size: 18px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}


@keyframes animateLogo
{
    0%
    {
      filter: contrast(1.8);
    }
    15%
    {
      filter: contrast(1.7) ;
    }
    25%
    {
      filter: contrast(1.6) ;
    }
    50%
    {
      filter: contrast(1.6) invert(0.3) brightness(1.75);
    }
    75%
    {
      filter: contrast(1.7);
    }
    85%
    {
      filter: contrast(1.8);
    }
    100%
    {
      filter: contrast(2);
    }
}
.title {

  background: linear-gradient(
    to left,
    #76f935 20%,
    #367417 30%,
    #76f935 70%,
    #76f935 80%
);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
-text-fill-color: transparent;
background-size: 500% auto;

}
@keyframes animateTitle
{
  0% {
    background-position: 0% 50%;
}
100% {
    background-position: 100% 50%;
}
}
/* ------------------------------------------- */


/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Card container */
.card {
  position: relative;
  max-width: 100%;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border-style: dashed;
  border-color: #76f935;

  
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -3px;
  bottom: -4.5px;
  border-width: 3px 0 3px 3px;
  border-radius: 50px 0 0 50px;
  
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
  .card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
  }
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 1.5px;
  bottom: 0;
  border-width: 3px 3px 3px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  background: #242424;
  color: gray;
  border-radius: 10px;
  padding: 10px;
}

/* Title of the card */
.timeline-title {
  color: #76f935;
  position: relative;
}

/* Timeline dot  */
.timeline-title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  border: 3px solid #76f935;
}

/* text right if the card is even  */
.card:nth-child(even) > .info > .timeline-title {
  text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .timeline-title::before {
  left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .timeline-title::before {
  right: -45px;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes moveToRight {
  0% { transform: translate(-20px);opacity: 0; }
  100% { transform: translate(0px);opacity: 1;}
}

@keyframes moveToLeft {
  0% { transform: translate(20px); }
  100% { transform: translate(0px);}
}
@keyframes moveToTop {
  0% { transform: perspective(1em) rotateX(11deg) scale(1.2);opacity: 0; }
  100% { ttransform: perspective(10em) rotateX(11deg) scale(1.2);opacity: 1;}
}



