.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel {
	width: 13.125rem;
	height: 13.125rem;
	position: relative;
	margin: 0 auto;
	perspective: 62.5rem;
}

.carousel__container {
	width: 100%;
	height: 100%;
	position: absolute;
	transform-style: preserve-3d;
	transition: transform 1s;
}

.carousel__slide {
	position: absolute;
	width: 11.875rem;
	height: 11.875rem;
	left: 0.625rem;
	top: 0.625rem;
	border: 0.125rem solid #76f935;
	transition: transform 1s, opacity 1s;
}

.carousel__slide img {
	width: 100%;
	height: 190px;
	display: block;
}

.carousel__slide-overlay {
	color: #fff;
	text-align: center;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
	background-color: rgba(0, 0, 0, 0.7);
	user-select: none;
}

.carousel__slide-overlay span,
.carousel__slide-overlay strong {
	display: block;
	flex: 0 0 100%;
}

.carousel__slide:hover .carousel__slide-overlay {
	opacity: 1;
}

.carousel__controls {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
}

.carousel__control {
	font-size: 0;
	line-height: 0;
	text-indent: -100%;
	width: 2.5rem;
	height: 2.5rem;
	display: block;
	position: relative;
	border: 1px solid;
	margin: 0 2rem;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	box-shadow: none;
}

.carousel__control::before,
.carousel__control::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.carousel__control::before {
	width: 50%;
	height: 1px;
	background-color: #76f935;
}

.carousel__control::after {
	content: '';
	width: 0.9375rem;
	height: 0.9375rem;
	display: inline-block;
	vertical-align: middle;
	border-color: #76f935;
	border-style: solid;
	border-width: 0 0 1px 1px;
	margin: auto;
	transform: rotate(-135deg);
	transform-origin: 50% 50%;
	transition: all 0.35s ease-in-out;
}

.carousel__control--prev::after {
	transform: rotate(45deg);
}
